<template>
  <div class="row">&nbsp;</div>
  <div class="container-fluid">
    <div class="row">
      <div class="col">
        <div class="row">
          <!-- <WelcomeCard /> -->
          <div class="col-xxl-12">
            <div class="row widget-grid">
              <OrderWelcomeCard />
              <!-- <OrderPurchaseSaleCard /> -->
              <div class="col-xl-4 col-sm-6 box-col-6">
                <div class="row">
                  <div class="col-xl-12">
                    <div class="card widget-1">
                      <div class="card-body">
                        <div class="widget-content">
                          <div class="widget-round secondary">
                            <div class="bg-round">
                              <svg class="svg-fill">
                                <use
                                  :xlink:href="
                                    require('@/assets/svg/icon-sprite.svg') +
                                    `#cart`
                                  "
                                ></use>
                              </svg>
                              <svg class="half-circle svg-fill">
                                <use
                                  :xlink:href="
                                    require('@/assets/svg/icon-sprite.svg') +
                                    `#halfcircle`
                                  "
                                ></use>
                              </svg>
                            </div>
                          </div>
                          <div>
                            <h4>{{ this.total_order }}</h4>
                            <span class="f-light"> คำสั่งซื้อ </span>
                          </div>
                          <div>&nbsp;</div>
                        </div>
                      </div>
                    </div>
                    <div class="card widget-1">
                      <div class="card-body">
                        <div class="widget-content">
                          <div class="widget-round primary">
                            <div class="bg-round">
                              <svg class="svg-fill">
                                <use
                                  :xlink:href="
                                    require('@/assets/svg/icon-sprite.svg') +
                                    `#income`
                                  "
                                ></use>
                              </svg>
                              <svg class="half-circle svg-fill">
                                <use
                                  :xlink:href="
                                    require('@/assets/svg/icon-sprite.svg') +
                                    `#halfcircle`
                                  "
                                ></use>
                              </svg>
                            </div>
                          </div>
                          <div>
                            <h4>{{ this.total_image }}</h4>
                            <span class="f-light"> ยอดขาย </span>
                          </div>
                          <div>&nbsp;</div>
                          <div>
                            <h4 v-if="this.total_image_all !== 0">
                              {{
                                Number(
                                  (this.total_image * 100) /
                                    this.total_image_all
                                ).toFixed(2)
                              }}
                              %
                            </h4>
                            <h4 v-else>0 %</h4>
                            <span class="f-light"> เปอร์เซ็นต์ยอดขาย </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="col-xl-4 col-sm-6 box-col-6">
                <div class="row">
                  <div class="col-xl-12">
                    <div class="card widget-1">
                      <div class="card-body">
                        <div class="widget-content">
                          <div class="widget-round success">
                            <div class="bg-round">
                              <svg class="svg-fill">
                                <use
                                  :xlink:href="
                                    require('@/assets/svg/icon-sprite.svg') +
                                    `#fill-gallery`
                                  "
                                ></use>
                              </svg>
                              <svg class="half-circle svg-fill">
                                <use
                                  :xlink:href="
                                    require('@/assets/svg/icon-sprite.svg') +
                                    `#halfcircle`
                                  "
                                ></use>
                              </svg>
                            </div>
                          </div>
                          <div>
                            <h4>{{ this.total_image_all }}</h4>
                            <span class="f-light"> รูปที่อัปโหลดเข้าระบบ </span>
                            <!-- <a :href="'/upload-images/'+this.$route.params.q" target="_blank">(รายชื่อรูปที่อัปโหลด)</a> -->
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="card widget-1">
                      <div class="card-body">
                        <div class="widget-content">
                          <div class="widget-round success">
                            <div class="bg-round">
                              <svg class="svg-fill">
                                <use
                                  :xlink:href="
                                    require('@/assets/svg/icon-sprite.svg') +
                                    `#profit`
                                  "
                                ></use>
                              </svg>
                              <svg class="half-circle svg-fill">
                                <use
                                  :xlink:href="
                                    require('@/assets/svg/icon-sprite.svg') +
                                    `#halfcircle`
                                  "
                                ></use>
                              </svg>
                            </div>
                          </div>
                          <div>
                            <h4>{{ this.p_share }}</h4>
                            <span class="f-light">
                              ส่วนแบ่งช่างภาพ({{ this.photographer_share }}%)
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- <AllCampaignsCard /> -->

      <div class="col-xl-12">
        <div class="card">
          <div class="card-header">
            <div class="header-top">
              <h5 class="m-0">รายการขาย</h5>
              <button
                  type="button"
                  @click.prevent="loadOrder()"
                  class="btn btn-primary"
                >
                  <vue-feather type="refresh-cw" size="18"> </vue-feather>
                  Refresh
                </button>
            </div>
          </div>
          <div class="table-responsive" v-if="this.is_mhao === 'N'">
            <table class="table table-striped">
              <thead>
                <tr>
                  <th scope="col" style="text-align: center">
                    <b>คำสั่งซื้อ</b>
                  </th>
                  <th style="text-align: center"><b>รูปที่ขายได้</b></th>
                  <th style="text-align: center"><b>ราคา</b></th>
                  <th style="text-align: left"><b>วันที่ชำระเงิน</b></th>
                  <th style="text-align: left"><b>ชื่อ</b></th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="order in this.orders" :key="order">
                  <td scope="row" style="text-align: center">
                    <b>{{ order.confirm_key }}</b>
                  <br>
                    <p v-if="order.thumbnail_file_name !== 'hybrid_order'">(1/{{ order.order_image_length }})</p>
                    <p v-if="order.thumbnail_file_name === 'hybrid_order'">({{ Number(order.system_share)}}/{{ order.order_image_length }})</p>
                  </td>
                  <td scope="row" style="text-align: left">
                    <img :alt="order.original_file_name"
                      v-if="order.order_id > 5000 && order.thumbnail_file_name !== 'hybrid_order'"
                      :src="
                        this.s3_url +
                        order.event_key +
                        '/thumbnail/' +
                        order.thumbnail_file_name
                      "
                      style="height: 200px; border: 3px solid #000000;"
                    />

                    <a v-if="order.order_id > 5000 && order.thumbnail_file_name === 'hybrid_order'" :href="this.$route.params.q +
                              '/view/' +
                              order.confirm_key +
                              order.order_id" target="_blank" >
                    <img :alt="order.original_file_name"
                      src="../../assets/images/running/order-share.png"
                      style="height: 200px; border: 1px solid #000000;border-radius: 10px;" /></a>
                    
                    <img :alt="order.original_file_name"
                      v-if="order.order_id < 5000"
                      :src="order.thumbnail_file_name"
                      style="height: 200px; border: 3px solid #000000;"
                    />
                  </td>
                  <td style="text-align: center">
                    {{ order.user_share }}
                  </td>
                  <td style="text-align: left">
                    {{ order.payment_date }}
                  </td>
                  <td style="text-align: left">
                    {{ order.original_file_name }}
                  </td>
                </tr>
              </tbody>

              <tbody v-if="this.total_image <= 0">
                <tr>
                  <td colspan="5">
                    <div class="d-flex justify-content-center">ไม่พบข้อมูล</div>
                  </td>
                </tr>
              </tbody>
            </table>
            <p>&nbsp;</p>
            <div class="d-flex justify-content-center">
              <paginate
                v-if="this.total_image > this.init_page_size"
                v-model="page"
                :page-count="this.page_tab"
                :page-range="3"
                :margin-pages="2"
                :clickHandler="clickCallback"
                :prev-text="'Prev'"
                :next-text="'Next'"
                :container-class="'pagination pagination-sm'"
                :page-class="'page-item'"
              >
              </paginate>
            </div>
            <b style="color: #ffffff"
              >({{ Number(this.total_amt - this.p_share).toFixed(0) }})</b
            >
            <div
              class="d-flex justify-content-center"
              v-if="this.loadding === 'Y'"
            >
              <img
                style="align-items: center; width: 60px"
                src="../../assets/images/running_loading.svg"
              />
            </div>
          </div>
          <div v-if="this.is_mhao !== 'N' && this.loadding === 'N'">
            <div class="d-flex justify-content-center">
              this page is not authorized
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<style scoped>
.table-fit {
  width: 1px;
}
</style>
<script>
import orderDetail from "./orderDetail.vue";
import OrderWelcomeCard from "./orderWelcomeCard.vue";
import VuejsPaginateNext from "./../../components/Paginate.vue";
import axios from "axios";
export default {
  name: "order",
  components: {
    orderDetail,
    OrderWelcomeCard,
    paginate: VuejsPaginateNext,
  },
  setup() {},
  data() {
    return {
      orders: [],
      is_mhao: null,
      order_count: 0,
      page_tab: 0,
      loadding: "N",
      page: 1,
      init_page_size: 20,

      total_order: 0,
      total_amt: null,
      p_share: 0,
      total_image: 0,
      total_image_all: 0,
      photographer_share: 60,
      s3_url: "https://rnth.s3.ap-southeast-1.amazonaws.com/",
      //s3_url: process.env.VUE_APP_AWS_S3_URL,s3_url:
    };
  },
  async mounted() {
    //this.user = this.user_store.user.body;
    
    this.loadOrder();
  },
  methods: {
    async loadOrder(){
      this.total_order = 0;
      this.total_amt = 0;
      this.p_share = 0;
      this.total_image = 0;
      this.total_image_all = 0;
      this.photographer_share = 0;
      this.loadding = "Y";
      try {
        const response_summery = await axios.get(
          "/v1/order/order-summery/" + this.$route.params.q,
          {
            withCredentials: true,
          }
        );
        if (response_summery.data.is_mhao !== "Y") {
          if (response_summery.data.body.total_order !== null) {
            
            this.total_order = response_summery.data.body.total_order;
            this.total_amt = response_summery.data.body.total_amt;
            this.p_share = response_summery.data.body.p_share;
            this.total_image = response_summery.data.body.total_image;
            this.total_image_all = response_summery.data.body.total_image_all;
            this.photographer_share =
              response_summery.data.body.photographer_share;

            // set for detail pagination //
            this.order_count = this.total_order;
            this.page_tab = Math.round(
              this.total_image / this.init_page_size + 0.5
            );
            //----------------//

            let data = {
              page: 1,
              page_size: this.init_page_size,
              order_count: this.order_count,
              page_tab: this.page_tab,
            };
            this.orders = null
            const response = await axios.post(
              "/v1/order/order-paid-pagination/" + this.$route.params.q,
              data,
              {
                withCredentials: true,
              }
            );
            //console.log("response"+JSON.stringify(response));
            if (response.data.status === "success") {
              this.orders = response.data.body;
              this.is_mhao = response.data.is_mhao;
              this.loadding = "N";
            } else {
              alert("Load order พบข้อผิดพลาด!");
            }
          }
        }
      } catch (error) {
        alert("พบข้อผิดพลาดกรุณาติดต่อผู้ดูแลระบบ", error);
        //this.$router.push({ path: "/auth/login" });
      }
    },
    async clickCallback(pageNum) {
      let data = {
        page: pageNum,
        page_size: this.init_page_size,
        order_count: this.order_count,
        page_tab: this.page_tab,
      };
      const response = await axios.post(
        "/v1/order/order-paid-pagination/" + this.$route.params.q,
        data,
        {
          withCredentials: true,
        }
      );
      this.orders = response.data.body;
    },
  },
};
</script>
