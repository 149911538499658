import axios from 'axios'

//axios.defaults.baseURL='http://localhost:8082'
axios.defaults.baseURL='https://photographerapi.running.in.th'
//axios.defaults.baseURL='https://photorunning.in.th'
//axios.defaults.baseURL='https://photographerapi.photorunning.in.th'

axios.defaults.withCredentials = true;

axios.defaults.headers.common['Authorization'] = `${localStorage.getItem('jwt')}`;
