<template>
  <li class="profile-nav onhover-dropdown pe-0 py-0">
    <div class="media profile-media">
      <img
        v-if="
          this.profile_image_url !== null &&
          this.profile_image_url !== '' &&
          this.profile_image_url !== 'null'
        "
        class="b-r-10"
        style="height: 45px; width: 45px"
        :src="this.profile_image_url"
        alt=""
      />

      <img
        v-else
        class="b-r-10"
        style="height: 45px; width: 45px"
        src="@/assets/images/running/running-logo-s.png"
        alt=""
      />

      <div class="media-body">
        <span v-if="this.user"
          >{{ this.user.name }} {{ this.user.sername }}</span
        >

        <p class="mb-0 font-roboto" v-if="this.user.role_name">
          {{ this.user.role_name }} <i class="middle fa fa-angle-down"></i>
        </p>
        <p class="mb-0 font-roboto" v-if="!this.user.role_name">
          photographer <i class="middle fa fa-angle-down"></i>
        </p>
      </div>
    </div>
    <ul class="profile-dropdown onhover-show-div">
      <!-- 
        <li>
        <router-link :to="'/users/edit'"
          ><vue-feather type="user"></vue-feather
          ><span>Account </span></router-link
        >
      </li>
      <li>
        <router-link :to="'/email/readMail'"><vue-feather type="mail"></vue-feather><span>Inbox</span></router-link>
      </li>
      <li>
        <router-link :to="'/app/task'"><vue-feather type="file-text"></vue-feather><span>Taskboard</span></router-link>
      </li>-->
      <li>
        <router-link :to="'/users/edit'"
          ><vue-feather type="settings"></vue-feather
          ><span>Settings</span></router-link
        >
      </li>
      <li
        v-if="
          this.user.role_name === 'system' ||
          this.user.role_name === 'systemadmin'
        "
      >
        <router-link :to="'/system-dashboard'"
          ><vue-feather type="settings"></vue-feather
          ><span>System dashbord</span></router-link
        >
      </li>
      <li>
        <router-link :to="'/partner'"
          ><vue-feather type="calendar"></vue-feather
          ><span>PARTNER</span></router-link
        >
      </li>
      <li>
        <a @click="logout"
          ><vue-feather type="log-in"></vue-feather><span>Log out</span></a
        >
      </li>
    </ul>
  </li>
</template>

<script>
import axios, { AxiosHeaders } from "axios";
import { useCookies } from "vue3-cookies";
export default {
  name: "Profile",
  setup() {
    const { cookies } = useCookies();
    return { cookies };
  },
  data() {
    return {
      user: {},
      profile_image_url:
        "https://photographer.running.in.th/images/running-logo-s.png",
    };
  },
  methods: {
    logout: async function () {
      this.cookies.remove("jwt");
      localStorage.removeItem("jwt");
      localStorage.removeItem("user");
      localStorage.removeItem("user_id");
      location.reload();
      this.$router.replace("/auth/login");
    },
  },
  computed: {
    //...mapGetters(["user"]),
  },

  async mounted() {
    this.user = JSON.parse(localStorage.getItem("user"));
    //console.log("profile: this.user", this.user);
    this.cookies.set(
      "jwt",
      localStorage.getItem("jwt"),
      "100d",
      "/",
      "/",
      true,
      "strict"
    );

    try {
      const responseUser = await axios.get("/v1/user/sign-signature", {
        withCredentials: true,
        // headers: {
        //   'Authorization': localStorage.getItem("jwt")
        // }
      });

      if (responseUser.data.status === "success") {
        this.user = JSON.parse(JSON.stringify(responseUser.data.body));
        localStorage.setItem("user_id", this.user.user_id);
        localStorage.setItem("user", JSON.stringify(this.user));

        this.profile_image_url = this.user.profile_image_url;
        //console.log("profile:::"+JSON.stringify(this.user));
      } else {
        //this.$router.push({ path: "/auth/login" });
        this.logout();
        location.reload();
      }
    } catch (error) {
      //this.$router.push({ path: "/auth/login" });
    }
  },
};
</script>
